<template>
  <div>
    <Header navName="case"></Header>
    <!-- PC端 -->
    <div class="case_PC" v-if="!$isAndroid">
      <!-- banner -->
      <div class="banner">
        <img src="@/static/case/banner.png" />
      </div>
      <!-- 服务内容 -->
      <div class="home_servicecon">
        <div class="home_title" style="padding-top: 75px;">
          <p class="title">服务内容</p>
          <p class="con">我们拥有经验丰富的专业团队，致力于提供最佳互联网解决方案</p>
        </div>
        <div class="home_servicecon_con">
          <div  :class="['home_servicecon_con_region',item.path!=''?'hover':'']" v-for="(item, index) in anList" :key="index" @click="pathFun(item.path)">
            <img :src="require(`@/static/home/${item.icon}`)" width="90px" height="auto">
            <p class="wrap_title">{{ item.name }}<span v-if="item.names!=''">{{item.names}}</span></p>
            <div class="home_wrap_text">
              <div class="item" v-for="(itemk, indexk) in item.items" :key="indexk">{{ itemk }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 顾问式服务 -->
      <div class="home_service">
        <div class="home_title">
          <p class="title">顾问式服务</p>
          <p class="con">规范化开发流程，为用户提供更全、更优质的产品解决方案</p>
        </div>
        <div class="home_service_con">
          <img src="@/static/home/home_seyvice1.gif" alt="项目流程">
          <div class="home_service_text" :class="'service_text' + Number(index + 1)" v-for="(item, index) in yxList"
            :key="index">
            <p class="num">{{ item.num }}</p>
            <p class="title">{{ item.title }}</p>
          </div>
        </div>
      </div>
      <!-- 项目管理保障 -->
      <div class="service_guarantee">
        <div class="home_title">
          <p class="title">项目管理保障</p>
          <p class="con">专业化软件项目管理执行标准，研发进度质量双保障</p>
        </div>
        <div class="service_guarantee_con">
          <img class="service_guarantee_img" src="@/static/case/service_guarantee.png"
            alt="项目管理保障,专业化软件项目管理执行标准，研发进度质量双保障">
          <div class="" :class="['service_guarantee_p','service_guarantee_p'+Number(index+1)]" v-for="(item, index) in guList" :key="index">
            <p><span></span>{{item.title}}</p>
            <div>{{item.con}}</div>
          </div>
        </div>
      </div>
      <!-- 客户品牌 -->
      <div class="service_partner">
        <div class="home_title">
            <p class="title">客户品牌</p>
            <p class="con">极砚科技为众多知名品牌提供软件开发服务</p>
        </div>
        <div class="service_partner_con">
          <img alt="极砚科技"
            :src="require(`@/static/home/${item.img}`)"
            v-for="(item, index) in pinListPC" :key="index">
        </div>
    </div>

    </div>
    <div class="case_H5" v-else>
      <!-- banner -->
      <div class="banner">
        <img src="@/static/case/banner.png" />
      </div>
      <!-- 服务内容 -->
      <div class="serves">
        <div class="title_content">
          <div class="title">服务内容</div>
          <div class="title_con">我们致力于为客户提供最佳互联网解决方案</div>
        </div>
        <div class="serveList">
          <div class="serveItem" v-for="(item, index) in serveList" :key="index" @click="pathFun(item.path)">
            <div class="serveItemLeft">
              <img :src="require(`@/static/case/${item.icon}`)" :alt="item.title1 || item.title">
            </div>
            <div class="serveItemRight">
              <div class="serveTitle">
                {{ item.title }}<span class="que" v-if="item.title1">{{ item.title1 }}</span>
              </div>
              <div class="serveIntroduce">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 顾问式服务 -->
      <div class="adviser">
        <div class="title_content">
          <div class="title">顾问式服务</div>
          <div class="title_con">为用户提供更多、更全、更优质的产品解决方案</div>
        </div>
        <div class="adviser_list">
          <div class="rightOut_img">
            <img src="@/static/case/rightimg1.png">
          </div>
          <div class="leftOut_img">
            <img src="@/static/case/leftimg1.png">
          </div>
          <div class="adviser_item">
            <img src="@/static/case/negotiation1.png" class="adviser_item_img" alt="">
            <div class="adviser_item_title">
              意向洽谈
            </div>
          </div>
          <div class="and_right">
            <img src="@/static/case/arrow1.png" alt="">
          </div>
          <div class="adviser_item">
            <img src="@/static/case/demand1.png" class="adviser_item_img" alt="">
            <div class="adviser_item_title">
              需求梳理
            </div>
          </div>
          <div class="and_right">
            <img src="@/static/case/arrow1.png" alt="">
          </div>
          <div class="adviser_item">
            <img src="@/static/case/cooperation1.png" class="adviser_item_img" alt="">
            <div class="adviser_item_title">
              签约合作
            </div>
          </div>
          <div class="and_right">
            <img src="@/static/case/arrow1.png" alt="">
          </div>
          <div class="adviser_item">
            <img src="@/static/case/prototype1.png" class="adviser_item_img" alt="">
            <div class="adviser_item_title">
              原型设计
            </div>
          </div>
          <div class="adviser_item">
            <img src="@/static/case/ui2.png" class="adviser_item_img" alt="">
            <div class="adviser_item_title">
              UI设计
            </div>
          </div>
          <div class="and_right">
            <img src="@/static/case/arrow1.png" alt="">
          </div>
          <div class="adviser_item">
            <img src="@/static/case/development1.png" class="adviser_item_img" alt="">
            <div class="adviser_item_title">
              系统开发
            </div>
          </div>
          <div class="and_right">
            <img src="@/static/case/arrow1.png" alt="">
          </div>
          <div class="adviser_item">
            <img src="@/static/case/test1.png" class="adviser_item_img" alt="">
            <div class="adviser_item_title">
              测试交付
            </div>
          </div>
          <div class="and_right">
            <img src="@/static/case/arrow1.png" alt="">
          </div>
          <div class="adviser_item">
            <img src="@/static/case/operate1.png" class="adviser_item_img" alt="">
            <div class="adviser_item_title">
              上线运营
            </div>
          </div>
        </div>
      </div>
      <!-- 项目管理保障 -->
      <div class="guarantee">
        <div class="title_content">
          <div class="title">项目管理保障</div>
          <div class="title_con">专业化软件项目管理执行标准，研发进度质量双保障</div>
        </div>
        <div class="guarantee_list">
          <div class="guarantee_list_item" v-for="(item, index) in guaranteeList" :key="index">
            <div class="guarantee_list_item_top">
              <img :src="require(`@/static/case/${item.icon}`)">
            </div>
            <p class="guarantee_list_item_title">{{ item.name1 }}<br>{{ item.name2 }}</p>
          </div>
        </div>
      </div>
      <!-- 客户品牌墙 -->
      <div class="home_customer">
        <div class="title_content">
          <div class="title">客户品牌墙</div>
          <div class="title_con">极砚科技为众多知名品牌提供软件开发服务</div>
          <div class="heng"></div>
        </div>
        <div class="home_customer_list">
          <img class="home_customer_item" :src="require(`@/static/home/${item.img}`)" v-for="(item, index) in pinList"
            :key="index">
        </div>
      </div>


    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      serveList: [
        {
          icon: 'wzjs1.png',
          title: "企业网站建设",
          title1: "",
          name: "生活服务、企业展示、电商/零售、餐饮/外卖",
          path: "",
        },
        {
          icon: 'wxxcx1.png',
          title: "微信/小程序开发",
          title1: "",
          name: "微官网、微商城、H5活动、微分销",
          path: "",
        },
        {
          icon: 'appkf1.png',
          title: "APP开发（苹果+安卓）",
          title1: "",
          name: "电商类、知识付费类、直播类、旅游平台类",
          path: "",
        },
        {
          icon: 'cylogo1.jpg',
          title: "高效办公",
          title1: "极砚OA",
          name: "企业数字化、信息化、客户管理、财务管理",
          path: "oa",
        }
      ],
      guaranteeList: [
        {
          icon: 'scheduling.png',
          name1: '清晰科学的研发',
          name2: '排期',
        },
        {
          icon: 'controlandcontrol.png',
          name1: '系统化的项目进度',
          name2: '管控模式',
        },
        {
          icon: 'examination.png',
          name1: '分节点与甲方提交',
          name2: '验收',
        },
        {
          icon: 'dimension.png',
          name1: '超时长的后期运维',
          name2: '服务',
        }
      ],
      pinList: [
        { img: 'h1.png' },
        { img: 'h2.png' },
        { img: 'h3.png' },
        { img: 'h4.png' },
        { img: 'h5.png' },
        { img: 'h6.png' },
        { img: 'h7.png' },
        { img: 'h8.png' },
        { img: 'h9.png' },
        { img: 'h10.png' },
        { img: 'h11.png' },
        { img: 'h12.png' },
      ],
      pinListPC: [
        { img: 'h1.png' },
        { img: 'h2.png' },
        { img: 'h3.png' },
        { img: 'h4.png' },
        { img: 'h5.png' },
        { img: 'h6.png' },
        { img: 'h7.png' },
        { img: 'h8.png' },
        { img: 'h9.png' },
        { img: 'h10.png' },
        { img: 'h11.png' },
        { img: 'h12.png' },
        { img: 'h13.png' },
        { img: 'h14.png' },
        { img: 'h15.png' },
        { img: 'h16.png' },
        { img: 'h17.png' },
        { img: 'h18.png' },
        { img: 'h19.png' },
        { img: 'h20.png' },
        { img: 'h21.png' },
      ],
      anList: [
        {
          name: "企业网站建设",
          names: "",
          icon: "wzjs.png",
          items: ['生活服务', '电商/零售', '企业展示', '餐饮/外卖'],
          path: ""
        },
        {
          name: "微信/小程序开发",
          names: "",
          icon: "wxxcx.png",
          items: ['微官网', 'H5活动', '微商城', '微分销'],
          path: ""
        },
        {
          name: "APP开发（苹果+安卓）",
          names: "",
          icon: "appkf.png",
          items: ['电商类', '直播类', '知识付费类', '旅游平台类'],
          path: ""
        },
        {
          name: "高效办公",
          names: "极砚OA",
          icon: "cylogo.jpg",
          items: ['企业数字化', '客户管理', '企业信息化', '财务管理'],
          path: "oa"
        }
      ],
      yxList: [
        {
          num: '01',
          title: '意向洽谈',
        },
        {
          num: '02',
          title: '需求梳理',
        },
        {
          num: '03',
          title: '签约合作',
        },
        {
          num: '04',
          title: '原型设计',
        },
        {
          num: '05',
          title: 'UI设计',
        },
        {
          num: '06',
          title: '系统开发',
        },
        {
          num: '07',
          title: '测试交付',
        },
        {
          num: '08',
          title: '上线运营',
        },
      ],
      guList: [
        {
          title: "清晰科学的研发排期",
          con: "每一个项目会分拆成众多小的模块并为此制定开发时间计划和相应人力安排，保障交付周期有效。"
        },
        {
          title: "系统化的项目进度管控模式",
          con: "利用专业的项目管理系统进行进度实时监控和专业的代码管理工具进行多人有序协同研发，保障项目进度和质量。"
        },
        {
          title: "分节点与甲方提交验收",
          con: "在研发开始前制定验收节点，分阶段进行项目的验收提交，保障系统开发结果与甲方预期相符。"
        },
        {
          title: "超时长的后期运维服务",
          con: "项目交付上线不代表服务的结束，项目团队仍然会尽职尽责对上线后的运维提供技术保障。"
        },
      ]
    }
  },
  mounted() {},
  methods: {
    pathFun(url) {
      if(url != '') {
        this.$router.push({ path: url });
      }
    },
  },
}
</script>

<style scoped lang='less'>
.case_PC {
  .banner {
    img {
      width: 100%;
      height: 370px;
      object-fit: cover;
      object-position: center center;
    }
  }

  .home_title {
    text-align: center;
    padding-top: 70px;

    .title {
      font-size: 24px;
      color: #222222;
      text-align: center;
    }

    .con {
      font-size: 12px;
      color: #999999;
      font-weight: 300;
      margin-top: 6px;
    }
  }

  .home_servicecon {
    width: 1200px;
    background: url(~@/static/home/home_toolbg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
    padding-bottom: 40px;
    margin: 0 auto;

    .home_servicecon_con {
      display: flex;
      justify-content: space-between;
      margin-top: 36px;
      padding: 40px 0;
      background: #fff;

      .home_servicecon_con_region {
        border-right: 1px solid #f0f0f0;
        background-color: #ffffff;
        text-align: center;
        &.hover{
          cursor: pointer;
        }

        .wrap_title {
          font-size: 20px;
          color: #444;
          span{
            color: #ff641f;
          }
        }

        .home_wrap_text {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-start;
          /* 如果需要垂直居中，可以使用align-items: center; */
          font-size: 16px;
          line-height: 30px;
          color: #999999;
          // margin: 20px auto;
          padding: 20px;

          /* 可选的内边距 */
          .item {
            flex: 0 0 50%;
            /* 每个项目占据50%宽度 */
            text-align: center;
            /* 文字水平居中 */
            line-height: 30px;
            /* 行高与容器高度相同可垂直居中文本 */
            box-sizing: border-box;
            /* 防止padding影响宽度 */
          }
        }
      }
    }
  }

  .home_service {
    width: 100%;
    height: 642px;
    background-color: #ffffff;

    .home_service_con {
      width: 1200px;
      height: 400px;
      margin: 33px auto 0;
      position: relative;
      padding-left: 3px;

      .home_service_text {
        position: absolute;
        text-align: center;

        .num {
          font-family: Arial-BoldMT;
          font-size: 20px;
          color: #222222;
        }

        .title {
          font-size: 18px;
          color: #222222;
        }
      }

      .service_text1 {
        left: 0;
        top: 119px;
      }

      .service_text2 {
        left: 176px;
        top: 333px;
      }

      .service_text3 {
        left: 328px;
        top: 118px;
      }

      .service_text4 {
        left: 493px;
        top: 333px;
      }

      .service_text5 {
        left: 664px;
        top: 118px;
      }

      .service_text6 {
        left: 811px;
        top: 333px;
      }

      .service_text7 {
        left: 977px;
        top: 116px;
      }

      .service_text8 {
        left: 1130px;
        top: 333px;
        white-space: nowrap;
      }
    }
  }

  .service_guarantee {
    width: 100%;
    height: 953px;
    background-color: #f8f8f8;
    padding-top: 1px;

    .service_guarantee_con {
      width: 1200px;
      margin: 0 auto;
      margin-top: 72px;
      height: 622px;
      position: relative;
      text-align: center;

      .service_guarantee_img {
        margin: 0 auto;
        margin-top: 6px;
        margin-left: -28px;
      }

      .service_guarantee_p {
        width: 240px;
        text-align: left;

        p {
          font-size: 18px;
          font-weight: bold;
          color: #222222;
          display: flex;

          span {
            display: inline-block;
            width: 4px;
            height: 18px;
            background-color: #0bd0c0;
            border-radius: 2px;
            margin-right: 10px;
            margin-top: 3px;
          }
        }

        div {
          width: 240px;
          text-align: justify;
          font-size: 16px;
          color: #666666;
          margin-top: 10px;
        }

        &.service_guarantee_p1 {
          position: absolute;
          left: 0;
          top: 0;
        }

        &.service_guarantee_p2 {
          position: absolute;
          left: 0;
          top: 450px;
        }

        &.service_guarantee_p3 {
          position: absolute;
          left: 912px;
          top: 70px;
        }

        &.service_guarantee_p4 {
          position: absolute;
          left: 932px;
          top: 444px;
        }
      }
    }
  }

  .service_partner{
    width: 100%;
    height: 500px;
    background-color: #ffffff;
    .service_partner_con {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      margin: 0 auto;
      img {
        margin: 24px 10px 0;
        width: 151px;
        height: 81px;
      }
    }
  }
}

.case_H5 {
  .title_content {
    .title {
      font-family: PingFangSC-Medium;
      font-size: 0.54rem;
      line-height: 0.54rem;
      font-weight: 700;
      color: #1d1d1d;
      text-align: center;
    }

    .title_con {
      color: rgba(153, 153, 153, 1);
      font-size: .27rem;
      font-weight: 400;
      line-height: .27rem;
      font-family: Microsoft YaHei;
      text-align: center;
      margin-top: .27rem;
    }

    .heng {
      width: 0.667rem;
      height: 0.046rem;
      background-color: #0bd0c0;
      border-radius: 0.02rem;
      margin: 0.32rem auto;
    }

  }


  .banner {
    img {
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 132px;
    }
  }

  .serves {
    margin: .4rem auto .04rem;
    width: 9.2rem;
    background: #FFF;

    .title {
      padding-top: .51rem;
    }

    .serveList {
      width: 100%;
      height: 100%;
      padding: 0 .4rem;
      margin-top: .43rem;

      .serveItem {
        height: 2.2rem;
        width: 100%;
        border-bottom: .01rem solid #E1E1E1;
        align-items: center;
        display: flex;

        &:last-child {
          border-bottom: none;
        }

        .serveItemLeft {
          width: .72rem;
          height: .72rem;
          text-align: center;

          img {
            max-width: .72rem;
            max-height: .72rem;
          }
        }

        .serveItemRight {
          margin-left: .59rem;

          .serveTitle {
            width: 7.1rem;
            font-size: .37rem;
            color: rgba(68, 68, 68, 1);
            line-height: .37rem;
            margin-bottom: .23rem;
            position: relative;

            .que {
              font-size: .37rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #FF641F;
              line-height: .37rem;
            }
          }

          .serveIntroduce {
            font-size: .32rem;
            font-family: Microsoft YaHei;
            font-weight: 300;
            color: rgba(102, 102, 102, 1);
            line-height: .32rem
          }
        }
      }
    }






  }

  .adviser {
    width: 100%;
    height: 7.16rem;
    padding: 1px 0;

    .title {
      margin-top: .63rem;
    }

    .adviser_list {
      width: 8.75rem;
      height: 3.75rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: .67rem auto 0;
      position: relative;

      .rightOut_img {
        width: .44rem;
        height: .14rem;
        top: .45rem;
        right: -.63rem;
        font-size: 0;
        position: absolute;

        img {
          width: .44rem;
          height: .05rem;
        }
      }

      .leftOut_img {
        width: .44rem;
        height: .14rem;
        top: 2.36rem;
        left: -.63rem;
        font-size: 0;
        position: absolute;

        img {
          width: .45rem;
          height: .14rem;
        }
      }

      .and_right {
        width: 1.15rem;
        height: .16rem;
        margin-top: .45rem;
        font-size: 0;

        img {
          width: 100%;
          height: 100%
        }
      }


      .adviser_item {
        text-align: center;

        .adviser_item_img {
          width: 1.07rem;
          height: 1.07rem
        }

        .adviser_item_title {
          font-size: .27rem;
          font-weight: 400;
          line-height: .27rem;
          font-family: Microsoft YaHei;
          color: rgba(40, 40, 44, 1);
        }
      }
    }




  }

  .guarantee {
    width: 10rem;
    height: 13.5rem;
    background: #FFF;
    padding: 1px .4rem;
    box-sizing: border-box;

    .title {
      padding-top: .63rem;
    }

    .guarantee_list {
      width: 100%;
      height: 11.06rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: .45rem;

      .guarantee_list_item {
        width: 4.2rem;
        height: 5.33rem;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(225, 225, 225, 1);
        border-radius: .2rem;
        overflow: hidden;

        &:nth-child(2) .guarantee_list_item_top {
          background: #f9f6f0;
        }

        &:nth-child(3) .guarantee_list_item_top {
          background: #f1f2f9;
        }

        &:nth-child(4) .guarantee_list_item_top {
          background: #edf2f8;
        }

        .guarantee_list_item_top {
          width: 4.27rem;
          height: 3.6rem;
          background: #ebfbfa;
          border-radius: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 2rem;
            height: 1.7rem;
          }
        }

        .guarantee_list_item_title {
          font-size: .35rem;
          line-height: .52rem;
          margin-top: .3rem;
          text-align: center
        }
      }
    }






  }

  .home_customer {
    width: 10rem;
    height: 7.12rem;
    background: #fff;
    padding: 1px 0;
    box-sizing: border-box;

    .title {
      margin: 0.83rem auto 0;
    }

    .home_customer_list {
      width: 100%;
      height: 3.69rem;
      padding: 0 0.4rem;
      box-sizing: border-box;
      margin: 0.53rem auto 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .home_customer_item {
        width: 2.1rem;
        height: 1.05rem;
        border: 1px solid #e1e1e1;
      }
    }

  }
}
</style>